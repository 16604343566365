<template>
  <OutsideContainer>
    <OutsideCard>
      <template #cardHeader>
        <BaseHeadline fake="2" color="highEmphasis">
          {{ $t("LOGIN.FORM.TITLE") }}
        </BaseHeadline>

        <BaseHeadline fake="2" color="highEmphasis" class="mb-7">
          {{ $t("LOGIN.FORM.SUB_TITLE") }}
        </BaseHeadline>
      </template>

      <LoginForm
        v-model="credentials"
        :hasToken="hasToken"
        @submit="authenticate"
        @reload="reload"
        :loading="isLoading"
        :reloading="isReloading"
      />
      <template #cardFooter>
        <span class="mr-2">
          <BaseLink
            :link="{
              url: $t('LOGIN.FORM.IMPRINT_URL'),
              target: '_blank',
            }"
          >
            {{ $t("LOGIN.FORM.IMPRINT_LINK") }}
          </BaseLink>
        </span>

        <span>
          <BaseLink
            :link="{
              url: $t('LOGIN.FORM.DATA_PROTECTION_URL'),
              target: '_blank',
            }"
          >
            {{ $t("LOGIN.FORM.DATA_PROTECTION_LINK") }}
          </BaseLink>
        </span>
      </template>
    </OutsideCard>
  </OutsideContainer>
</template>

<script>
import OutsideContainer from "@components/Layout/OutsideContainer.vue";
import LoginForm from "@views/Public/Login/partials/LoginForm.vue";
import OutsideCard from "@components/Layout/OutsideCard.vue";
import { mapActions } from "vuex";

export default {
  name: "Login",

  components: {
    LoginForm,
    OutsideCard,
    OutsideContainer,
  },

  data() {
    return {
      hasToken: false,
      isLoading: false,
      isReloading: false,
      credentials: {
        username: null,
        password: null,
        mfaToken: null,
      },
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("auth", ["saveAccessToken"]),
    ...mapActions("authUser", ["showAuthUser"]),

    async goToDashboard() {
      try {
        await this.$router.push({ name: "Dashboard" });
      } catch {
        return;
      }
    },

    async loginUser() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.auth.login(this.credentials);

        await this.saveAccessToken({
          accessToken: data.token.access_token,
          refreshToken: data.token.refresh_token,
        });

        await this.showAuthUser();

        await this.goToDashboard();
      } catch (err) {
        await this.notify({
          type: "error",
          message: "LOGIN.NOTIFICATION.ERROR_401",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async createToken() {
      this.isLoading = true;

      try {
        await this.$axios.auth.createMFATokenGuest(this.credentials);
        this.hasToken = true;
      } catch (err) {
        console.error(err);

        await this.notify({
          type: "error",
          message: "LOGIN.NOTIFICATION.ERROR_401",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async reload() {
      this.isReloading = true;
      try {
        await this.$axios.auth.createMFATokenGuest(this.credentials);
        this.hasToken = true;
      } catch (err) {
        console.error(err);

        this.notify({
          type: "error",
          message: "LOGIN.NOTIFICATION.ERROR_401",
        });
      } finally {
        this.isReloading = false;
      }
    },

    authenticate() {
      if (!this.hasToken) {
        this.createToken();
      } else {
        this.loginUser();
      }
    },
  },
};
</script>
