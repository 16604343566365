<template>
  <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
    <v-row>
      <v-col cols="12" class="mb-n3">
        <v-text-field
          :label="$t('LOGIN.FORM.USERNAME_LABEL')"
          filled
          v-model="loginValues.username"
          :rules="requiredRules"
        >
          <template v-slot:prepend-inner>
            <v-icon dense class="mr-2">fa fa-user-alt</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" class="mb-n3">
        <v-text-field
          :label="$t('LOGIN.FORM.PASSWORD_LABEL')"
          filled
          :type="passwordType ? 'password' : 'text'"
          v-model="loginValues.password"
          :rules="requiredRules"
        >
          <template v-slot:prepend-inner>
            <v-icon dense class="mr-2">fa fa-lock</v-icon>
          </template>
          <template v-slot:append>
            <v-icon
              @click="passwordType = !passwordType"
              dense
              v-text="!passwordType ? 'fa fa-eye' : 'fa fa-eye-slash'"
            />
          </template>
        </v-text-field>
      </v-col>

      <v-col>
        <MultiFactorAuth
          v-model.trim="loginValues.mfaToken"
          v-if="hasToken"
          @reload="$emit('reload')"
          :loading="reloading"
        />
      </v-col>

      <v-col cols="12" class="text-right mt-n4">
        <router-link :to="{ name: 'RequestNewPassword' }" class="text-decoration-none body-1">
          {{ $t("LOGIN.FORM.FORGOT_PASSWORD_LABEL") }}
        </router-link>
      </v-col>

      <v-col cols="12">
        <div class="text-right mt-3">
          <BaseButton @click="$emit('submit')" :loading="loading" :disabled="loading">
            {{ $t("LOGIN.FORM.BUTTON_LABEL") }}
          </BaseButton>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import formValidation from "@/mixins/formValidation";
import MultiFactorAuth from "@components/FormElements/MultiFactorAuth.vue";
import useEnterKey from "@/use/useEnterKey";
import removeWhiteSpace from "@helper/removeWhiteSpace";

export default {
  name: "LoginForm",

  mixins: [formValidation],

  components: {
    MultiFactorAuth,
  },

  setup(props, { emit }) {
    useEnterKey(() => emit("submit"));
  },

  props: {
    value: {
      type: undefined,
      required: true,
      default: () => ({
        username: null,
        password: null,
        mfaToken: null,
      }),
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    reloading: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasToken: {
      type: Boolean,
      required: false,
      default: false,
    },

    mfaToken: {
      get() {
        return removeWhiteSpace(this.value.mfaToke);
      },

      set() {
        return;
      },
    },
  },

  computed: {
    loginValues: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      passwordType: true,
    };
  },
};
</script>
