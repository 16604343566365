var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OutsideContainer',[_c('OutsideCard',{scopedSlots:_vm._u([{key:"cardHeader",fn:function(){return [_c('BaseHeadline',{attrs:{"fake":"2","color":"highEmphasis"}},[_vm._v(" "+_vm._s(_vm.$t("LOGIN.FORM.TITLE"))+" ")]),_c('BaseHeadline',{staticClass:"mb-7",attrs:{"fake":"2","color":"highEmphasis"}},[_vm._v(" "+_vm._s(_vm.$t("LOGIN.FORM.SUB_TITLE"))+" ")])]},proxy:true},{key:"cardFooter",fn:function(){return [_c('span',{staticClass:"mr-2"},[_c('BaseLink',{attrs:{"link":{
            url: _vm.$t('LOGIN.FORM.IMPRINT_URL'),
            target: '_blank',
          }}},[_vm._v(" "+_vm._s(_vm.$t("LOGIN.FORM.IMPRINT_LINK"))+" ")])],1),_c('span',[_c('BaseLink',{attrs:{"link":{
            url: _vm.$t('LOGIN.FORM.DATA_PROTECTION_URL'),
            target: '_blank',
          }}},[_vm._v(" "+_vm._s(_vm.$t("LOGIN.FORM.DATA_PROTECTION_LINK"))+" ")])],1)]},proxy:true}])},[_c('LoginForm',{attrs:{"hasToken":_vm.hasToken,"loading":_vm.isLoading,"reloading":_vm.isReloading},on:{"submit":_vm.authenticate,"reload":_vm.reload},model:{value:(_vm.credentials),callback:function ($$v) {_vm.credentials=$$v},expression:"credentials"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }