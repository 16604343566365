<template>
  <v-text-field
    :label="$t('BASE.TOKEN_GENERATOR.TEXT_FIELD_MFA_TOKEN_LABEL')"
    filled
    v-model.trim="mfaValue"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #prepend-inner>
      <v-icon dense class="mr-2">far fa-key</v-icon>
    </template>
    <template #append>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn icon small v-on="on" @click="$emit('reload')" :loading="loading">
            <v-icon small color="primary">fa fa-sync-alt</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("BASE.TOKEN_GENERATOR.TEXT_FIELD_REFRESH_TOKEN_LABEL") }}</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "MultiFactorAuth",

  props: {
    value: {
      type: undefined,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    mfaValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
